<template>
  <div :class="['divider',{'divider--type-decorative' : type === 'decorative'},
														{'divider--size-small' : size === 'small'}]">
		<slot></slot>
	</div>
</template>

<script>
export default {
	props: {
		size: String,
		type: String,
	},
	data(){
		return{
			
		}
	},
	methods: {
	},
}

</script>

<style lang="scss" scoped>
.divider{
	width: var(--divider-width);
	height: var(--divider-height);
	background: var(--divider-background);
	margin: var(--divider-margin);
}
.divider--size-small{
	margin: var(--divider-small-margin);
}
.divider--type-decorative{
	height: var(--divider-decorative-height);
	width: var(--divider-decorative-width);
	margin: var(--divider-decorative-margin);
	background: var(--divider-decorative-background);
}

</style>

<!-- Used in : AR, DS -->